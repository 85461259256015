import type { TAnimationAtLanding } from '@/types';
import type { IAnimationAtLanding } from '@/interfaces';

const ANIMATION_AT_LANDING_360: IAnimationAtLanding = {
    name: '360',
    angle: 360,
};

const ANIMATION_AT_LANDING_360_WITH_ZOOM: IAnimationAtLanding = {
    name: '360-with-zoom',
    angle: 360,
    withZoom: true,
};

const ANIMATION_AT_LANDING_90_WITH_ZOOM: IAnimationAtLanding = {
    name: '90-with-zoom',
    angle: 90,
    initialPosition: {
        theta: -45,
    },
    withZoom: true,
};

export const ANIMATION_AT_LANDING_FALLBACK = ANIMATION_AT_LANDING_90_WITH_ZOOM;

export const ANIMATIONS_AT_LANDING: IAnimationAtLanding[] = [
    ANIMATION_AT_LANDING_360,
    ANIMATION_AT_LANDING_360_WITH_ZOOM,
    ANIMATION_AT_LANDING_90_WITH_ZOOM,
];

export const ANIMATIONS_AT_LANDING_VALUES: TAnimationAtLanding[] = [
    ANIMATION_AT_LANDING_360.name,
    ANIMATION_AT_LANDING_360_WITH_ZOOM.name,
    ANIMATION_AT_LANDING_90_WITH_ZOOM.name,
];
