<template>
    <Teleport to="body" :disabled="isEmbedded">
        <div
            :class="{
                'rtr-mv-overlay rtr-tw-fixed': isOverlay,
                'rtr-tw-h-full': isEmbedded,
            }"
        >
            <div
                id="rtr-master-version"
                class="Text-lgRegular rtr-tw-relative rtr-tw-h-full"
            >
                <Transition name="rtr-loaded">
                    <AppLoader
                        v-if="!isLoaded"
                        id="rtr-loader"
                        class="rtr-tw-pointer-events-none rtr-tw-absolute rtr-tw-h-full rtr-tw-w-full"
                    />
                </Transition>

                <CloseButton
                    v-if="isOverlay"
                    id="rtr-close-button"
                    class="rtr-tw-pointer-events-auto rtr-tw-absolute rtr-tw-top-6 rtr-tw-right-6"
                    :class="{ 'portrait:rtr-tw-top-[17%]': applicativeZoom }"
                    :disabled="isTutorialActive"
                />

                <AppError
                    v-if="error"
                    id="rtr-error-container"
                    class="Text-lgRegular rtr-tw-absolute rtr-tw-h-full rtr-tw-w-full"
                />

                <component
                    v-if="isRtrLoaded && !displayAnimationAtLanding"
                    :is="layoutComponent"
                    id="rtr-layout"
                    class="rtr-tw-pointer-events-none rtr-tw-absolute"
                />

                <div
                    id="rtr-viewer-container"
                    class="rtr-tw-absolute rtr-tw-h-full rtr-tw-w-full"
                    :class="{
                        'rtr-viewer-container-overlay':
                            isTutorialAnimationFinished &&
                            !displayFullscreenTutorial,
                        'rtr-tw-pointer-events-none': disableRtrInteraction,
                    }"
                />
            </div>
        </div>
    </Teleport>
</template>

<script setup lang="ts">
    import AppLoader from '@/components/loader/AppLoader.vue';
    import AppError from '@/components/common/AppError.vue';
    import CloseButton from '@/components/common/CloseButton.vue';

    import { useAnimationsStore } from '@/stores/animations';
    import { useDataStore } from '@/stores/data';
    import { useErrorsStore } from '@/stores/errors';
    import { useTutorialStore } from '@/stores/tutorial';
    import { useUiStore } from '@/stores/ui';
    import { useZoomStore } from '@/stores/zoom';

    import {
        dispose as disposeRtr,
        // setClearColor as setRtrClearColor,
    } from '@luxottica/rtr-viewer';

    // import type { TColor } from '@luxottica/rtr-viewer';

    import {
        ref,
        nextTick,
        onMounted,
        defineAsyncComponent,
        computed,
        onBeforeUnmount,
        watch,
    } from 'vue';
    import { storeToRefs } from 'pinia';
    // import { useMutationObserver } from '@vueuse/core';

    import { asyncTimeout } from '@/utils/promises';

    import {
        EXPERIENCE_OVERLAY,
        EXPERIENCE_EMBEDDED,
        DEFAULT_EMBEDDED_OVERVIEW_TIME,
    } from '@/constants';

    import { resetCamera } from '@luxottica/rtr-viewer';

    const animationsStore = useAnimationsStore();
    const dataStore = useDataStore();
    const errorsStore = useErrorsStore();
    const tutorialStore = useTutorialStore();
    const uiStore = useUiStore();
    const zoomStore = useZoomStore();

    const { isRotationActive, displayAnimationAtLanding } =
        storeToRefs(animationsStore);
    const { forceIdUpdate } = storeToRefs(dataStore);
    const { error } = storeToRefs(errorsStore);
    const { isActive: isTutorialActive } = storeToRefs(tutorialStore);
    const {
        experience,
        isRtrLoaded,
        isUiLoaded,
        displayFullscreenTutorial,
        displayOverview,
        isLandscape,
        isHigherThan600,
    } = storeToRefs(uiStore);

    const { applicativeZoom } = storeToRefs(zoomStore);

    // const clearColor = ref(null);
    const isTutorialAnimationFinished = ref(false);

    const matchMaxHeight600 = window.matchMedia('(max-height: 600px)');
    const matchIsLandscape = window.matchMedia('(orientation: landscape)');

    const disableRtrInteraction = computed(() => isRotationActive.value);

    //  const {stop: stopObserver} = useMutationObserver(
    //     document.documentElement,
    //     mutations => {
    //         const mutationTarget = mutations[0].target as HTMLElement;

    //         if (mutationTarget?.style) {
    //             clearColor.value = getComputedStyle(
    //                 document.documentElement
    //             ).getPropertyValue('--base-gray-ultra-light');
    //         }
    //     },
    //     {
    //         attributes: true,
    //         attributeFilter: ['style'],
    //     }
    // );

    const isLoaded = computed(() => isRtrLoaded.value && isUiLoaded.value);

    const isOverlay = computed(() => experience.value === EXPERIENCE_OVERLAY);
    const isEmbedded = computed(() => experience.value === EXPERIENCE_EMBEDDED);

    const layoutEmbedded = defineAsyncComponent(
        () => import(`@/modules/embedded/components/layout/Layout.vue`)
    );

    const layoutOverlay = defineAsyncComponent(
        () => import(`@/modules/overlay/components/layout/Layout.vue`)
    );

    const layoutComponent = computed(() => {
        if (isOverlay.value) return layoutOverlay;

        return layoutEmbedded;
    });

    const updateIsHigherThan600 = (e: MediaQueryListEvent) => {
        isHigherThan600.value = !e.matches;
    };

    const updateOrientation = (e: MediaQueryListEvent) => {
        isLandscape.value = e.matches;
    };

    matchMaxHeight600.addEventListener('change', updateIsHigherThan600);
    matchIsLandscape.addEventListener('change', updateOrientation);

    watch(isLoaded, async value => {
        if (value) {
            if (forceIdUpdate.value) {
                resetCamera();

                forceIdUpdate.value = false;
                return;
            }

            await animationsStore.toggleAnimationAtLanding();

            if (experience.value === EXPERIENCE_EMBEDDED) {
                await nextTick();

                displayOverview.value = true;

                await asyncTimeout(DEFAULT_EMBEDDED_OVERVIEW_TIME);

                displayOverview.value = false;
            }
        }
    });

    // watch(clearColor, (value: TColor) => {
    //     if (value) {
    //         setRtrClearColor(value);
    //     }
    // });

    watch(isTutorialActive, async value => {
        if (value) {
            isTutorialAnimationFinished.value = true;
            return;
        }

        const root = document.documentElement;
        const duration = Number(
            getComputedStyle(root).getPropertyValue('--rtr-transition-duration')
        );

        await asyncTimeout(duration);

        isTutorialAnimationFinished.value = false;
    });

    onMounted(() => {
        isHigherThan600.value = !matchMaxHeight600.matches;
        isLandscape.value = matchIsLandscape.matches;
    });

    onBeforeUnmount(() => {
        matchMaxHeight600.removeEventListener('change', updateIsHigherThan600);
        matchIsLandscape.removeEventListener('change', updateOrientation);

        disposeRtr();
    });
</script>

<style lang="scss">
    @import '@/style/main';
</style>

<style lang="scss" scoped>
    $viewerOffset: calc(
        root-var(rtr-footer-height) + root-var(rtr-tutorial-height)
    );

    #rtr-master-version {
        container-name: rtr-container;
        container-type: size;

        text-size-adjust: none;
        overflow: hidden;
        color: root-var(base-text-dark);
        // background-color: root-var(base-gray-ultra-light);
        background-color: $clr-background;
    }

    .rtr-mv-overlay {
        height: 100vh;
        height: 100dvh;
        width: 100%;

        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        z-index: root-var(rtr-overlay-z-index);
    }

    #rtr-loader {
        z-index: $layer-loader;
    }

    #rtr-close-button {
        z-index: $layer-close;
    }

    #rtr-error-container {
        z-index: $layer-error;
    }

    #rtr-layout {
        z-index: $layer-layout;
    }

    #rtr-viewer-container {
        z-index: $layer-viewer;
        transition: transform addSuffix(rtr-transition-duration, 1ms) ease;

        transform: translateY(calc(-1 * $viewerOffset / 2));

        &::after {
            content: '';
            pointer-events: none;
            position: absolute;
            bottom: -1px;
            width: 100%;
            height: 100px;
            background: linear-gradient(0deg, $clr-background, transparent);
            opacity: 0;

            transition: opacity addSuffix(rtr-transition-duration-short, 1ms)
                ease;
        }
    }

    .rtr-viewer-container-overlay::after {
        opacity: 1 !important;
    }

    .rtr-loaded-enter-active,
    .rtr-loaded-leave-active {
        transition: opacity addSuffix(rtr-transition-duration, 1ms) ease;
    }

    .rtr-loaded-enter-from,
    .rtr-loaded-leave-to {
        opacity: 0;
    }

    @media screen and (orientation: landscape) and (min-width: $breakpoint-l-md) {
        .rtr-mv-overlay {
            height: calc(100vh - 10vw);
            height: calc(100dvh - 10dvw);
            width: 90vw;
            width: 90dvw;

            box-shadow: root-var(alpha-black-dark) 20vw -20vw 0 500px;
        }
    }

    @media screen and (orientation: portrait) and (min-width: $breakpoint-p-md) {
        .rtr-mv-overlay {
            height: calc(100vh - 10vw);
            height: calc(100dvh - 10dvw);
            width: 90vw;
            width: 90dvw;

            box-shadow: root-var(alpha-black-dark) 20vw -20vw 0 500px;
        }
    }
</style>
