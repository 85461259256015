import { isString, isUndefined } from 'lodash-es';

export const validate = async (locale: string) => {
    if (isUndefined(locale)) {
        throw new Error(`The locale is required`);
    }

    if (!isString(locale)) {
        throw new TypeError(
            `The locale has to be a string. '${locale}' is not valid`
        );
    }
};
