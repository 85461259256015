<template>
    <Button
        :disabled="disabled"
        :tooltip="tooltip"
        :ariaLabel="t(TR_OVERLAY_TOOLTIP)"
        @click="handleClick"
    >
        <OverlaySvg />
    </Button>
</template>

<script setup lang="ts">
    import { TR_OVERLAY_TOOLTIP } from '@/constants';

    import Button from '@/components/buttons/Button.vue';

    import OverlaySvg from './assets/overlay.svg';

    import type { ITooltip } from '@/interfaces';

    import { useUiStore } from '@/stores/ui';

    import { computed, nextTick } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { storeToRefs } from 'pinia';

    import { resetCamera } from '@luxottica/rtr-viewer';

    const { t } = useI18n();

    interface IProps {
        disabled?: boolean;
    }

    defineProps<IProps>();

    const uiStore = useUiStore();

    const { experience } = storeToRefs(uiStore);

    const tooltip = computed(() => {
        const text = uiStore.displayOverview ? t(TR_OVERLAY_TOOLTIP) : null;

        const tooltip: ITooltip = {
            text,
            position: 'left',
        };

        return tooltip;
    });

    const handleClick = async () => {
        experience.value = 'overlay';

        await nextTick();
        resetCamera();
    };
</script>

<style lang="scss" scoped></style>
