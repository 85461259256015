<template>
    <div class="rtr-tw-relative" ref="tutorialBox">
        <button
            v-if="activeStepIndex !== lastStepIndex"
            id="rtr-tutorial-close"
            class="Text-mdRegular--Link rtr-tw-absolute rtr-tw-right-0 rtr-tw-top-0 rtr-tw-capitalize"
            :aria-label="$t(TR_TUTORIAL_CLOSE)"
            @click="closeTutorial"
        >
            {{ $t(TR_TUTORIAL_CLOSE) }}
        </button>

        <div
            id="rtr-tutorial-content"
            class="rtr-tw-grid rtr-tw-h-full rtr-tw-items-center rtr-tw-text-center"
            :class="{
                'rtr-tutorial-content--fullscreen': displayFullscreenTutorial,
            }"
        >
            <div
                v-if="displayFullscreenTutorial"
                class="rtr-tw-pointer-events-none rtr-tw-relative rtr-tw-h-full"
            >
                <TutorialAnimation />
            </div>

            <TutorialBoxStep />
        </div>
    </div>
</template>

<script setup lang="ts">
    import TutorialAnimation from '@/modules/overlay/components/tutorial/TutorialAnimation.vue';
    import TutorialBoxStep from '@/modules/overlay/components/tutorial/TutorialBoxStep.vue';

    import { useResizeObserver } from '@vueuse/core';

    import { TR_TUTORIAL_CLOSE, TUTORIAL_STEPS } from '@/constants';

    import { useTutorialStore } from '@/stores/tutorial';
    import { useUiStore } from '@/stores/ui';

    import { ref, onMounted, onBeforeUnmount } from 'vue';
    import { storeToRefs } from 'pinia';

    const tutorialStore = useTutorialStore();
    const uiStore = useUiStore();

    const { isActive: isTutorialActive, activeStepIndex } =
        storeToRefs(tutorialStore);
    const { displayFullscreenTutorial } = storeToRefs(uiStore);

    const tutorialBox = ref(null);

    const lastStepIndex = TUTORIAL_STEPS.length - 1;

    const { stop: stopObserver } = useResizeObserver(tutorialBox, entries => {
        const entry = entries[0];
        let { height } = entry.contentRect;

        if (displayFullscreenTutorial.value) {
            height = 0;
        }

        document.documentElement.style.setProperty(
            '--rtr-tutorial-height',
            height + 'px'
        );
    });

    const closeTutorial = () => {
        isTutorialActive.value = false;
    };

    onMounted(() => {
        activeStepIndex.value = 0;
    });

    onBeforeUnmount(() => {
        document.documentElement.style.setProperty(
            '--rtr-tutorial-height',
            '0px'
        );

        stopObserver();
    });
</script>

<style lang="scss" scoped>
    #rtr-tutorial-close {
        text-decoration: none;

        margin-top: 2%;
        margin-right: 3%;

        &:after {
            content: '';

            width: 100%;
            position: absolute;
            left: 0;
            bottom: 4px;

            border-width: 0 0 1px;
            border-color: root-var(base-text-black);
            border-style: solid;
        }
    }

    @media screen and (orientation: landscape) {
        .rtr-tutorial-content--fullscreen {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    @media screen and (max-height: 600px) and (orientation: portrait) {
        .rtr-tutorial-content--fullscreen {
            grid-template-rows: repeat(2, minmax(0, 1fr));
        }
    }
</style>
