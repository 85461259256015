<template>
    <div id="rtr-accessibility-container" class="rtr-tw-relative">
        <AccessibilityArrow
            v-if="displayLeftArrow"
            type="back"
            @click="scrollPosition -= 200"
            class="rtr-tw-absolute rtr-tw-left-0 rtr-tw-top-1/3 rtr-tw--translate-y-1/2 rtr-tw-z-10"
        />

        <div
            ref="buttonsContainerWrapper"
            id="rtr-accessibility-buttons-container"
            class="rtr-tw-py-4 rtr-tw-flex rtr-tw-w-full rtr-tw-overflow-x-auto"
            :class="{
                'rtr-tw-justify-center': !(
                    displayLeftArrow || displayRightArrow
                ),
            }"
        >
            <div ref="buttonsContainer" class="rtr-tw-flex rtr-tw-w-fit">
                <AccessibilityButton
                    v-for="button in ACCESSIBILITY_AVAILABLE_BUTTONS"
                    class="rtr-tw-px-8"
                    :key="button.name"
                    :data="button"
                />
            </div>
        </div>

        <AccessibilityArrow
            v-if="displayRightArrow"
            type="next"
            @click="scrollPosition += 200"
            class="rtr-tw-absolute rtr-tw-right-0 rtr-tw-top-1/3 rtr-tw--translate-y-1/2 rtr-tw-z-10"
        />
    </div>
</template>

<script setup lang="ts">
    import AccessibilityButton from './AccessibilityButton.vue';
    import AccessibilityArrow from './AccessibilityArrow.vue';

    import { useScroll, useResizeObserver } from '@vueuse/core';

    import { computed, onBeforeUnmount, ref } from 'vue';

    import { ACCESSIBILITY_AVAILABLE_BUTTONS } from '@/constants';

    const buttonsContainerWrapper = ref<HTMLElement | null>(null);
    const buttonsContainer = ref<HTMLElement | null>(null);

    const scrollEnd = ref(null);
    const scrollWidth = ref(null);

    const { x: scrollPosition } = useScroll(buttonsContainerWrapper, {
        behavior: 'smooth',
    });

    const displayLeftArrow = computed<boolean>(() => scrollPosition.value > 0);
    const displayRightArrow = computed<boolean>(() => {
        return scrollPosition.value < scrollEnd.value;
    });

    const { stop: stopObserveButtonsWrapper } = useResizeObserver(
        buttonsContainerWrapper,
        async entries => {
            const entry = entries[0];

            const { contentRect } = entry;

            const contentWidth = contentRect.width;

            scrollEnd.value =
                Math.round(scrollWidth.value) - Math.round(contentWidth);
        }
    );

    const { stop: stopObserveButtons } = useResizeObserver(
        buttonsContainer,
        async entries => {
            const entry = entries[0];

            const { contentRect } = entry;

            const contentWidth = contentRect.width;

            scrollWidth.value = contentWidth;
        }
    );

    onBeforeUnmount(() => {
        stopObserveButtons();
        stopObserveButtonsWrapper();
    });
</script>

<style lang="scss" scoped>
    #rtr-accessibility-container {
        background-color: root-var(base-white);
    }

    #rtr-accessibility-buttons-container {
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }
</style>
