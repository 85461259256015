<template>
    <div>
        <button
            id="rtr-close-button"
            :class="[
                disabled
                    ? 'rtr-tw-pointer-events-none'
                    : 'rtr-tw-pointer-events-auto',
            ]"
            :aria-label="$t(TR_OVERLAY_CLOSE)"
            @click="handleClose"
        >
            <CloseSvg class="rtr-icon" />
        </button>
    </div>
</template>

<script setup lang="ts">
    import { nextTick } from 'vue';
    import { storeToRefs } from 'pinia';

    import CloseSvg from './assets/close.svg?component';

    import { resetCamera } from '@luxottica/rtr-viewer';

    import {
        EXPERIENCE_EMBEDDED,
        EXPERIENCE_OVERLAY,
        TR_OVERLAY_CLOSE,
    } from '@/constants';

    import { useAccessibilityStore } from '@/stores/accessibility';
    import { useCallbacksStore } from '@/stores/callbacks';
    import { useExplosionStore } from '@/stores/explosion';
    import { useTutorialStore } from '@/stores/tutorial';
    import { useUiStore } from '@/stores/ui';

    interface IProps {
        disabled?: boolean;
    }

    defineProps<IProps>();

    const accessibilityStore = useAccessibilityStore();
    const callbacksStore = useCallbacksStore();
    const explosionStore = useExplosionStore();
    const tutorialStore = useTutorialStore();
    const uiStore = useUiStore();

    const { isActive: isExplosionActive, isFinished: isExplosionFinished } =
        storeToRefs(explosionStore);

    const resetFeatures = async () => {
        if (isExplosionActive.value) {
            await explosionStore.toggleExplosion();

            isExplosionActive.value = false;
        }

        accessibilityStore.isActive = false;
        tutorialStore.isActive = false;
        uiStore.isMenuActive = false;
    };

    const handleClose = async () => {
        callbacksStore.onClose();

        if (uiStore.initialExperience === EXPERIENCE_OVERLAY) {
            uiStore.forceUnmount = true;

            return;
        }

        if (!isExplosionFinished.value) {
            return;
        }

        await resetFeatures();

        uiStore.experience = EXPERIENCE_EMBEDDED;

        await nextTick();

        resetCamera();
    };
</script>

<style lang="scss" scoped>
    #rtr-close-button {
        --size: 56px;

        height: calc(var(--rtr-applicative-zoom) / 100 * var(--size));
        width: calc(var(--rtr-applicative-zoom) / 100 * var(--size));

        border-radius: 50%;

        clip-path: circle(50%);
    }

    .rtr-icon {
        margin: auto;
        transform: scale(calc(var(--rtr-applicative-zoom) / 100));

        & :deep(*) {
            fill: root-var(base-black) !important;
        }
    }
</style>
