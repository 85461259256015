<template>
    <div
        class="rtr-embedded-layout rtr-tw-h-full rtr-tw-w-full"
        :class="{ 'rtr-embedded-layout--overview': displayOverview }"
    >
        <LayoutMain class="rtr-tw-h-full rtr-tw-w-full" />
    </div>
</template>

<script setup lang="ts">
    import LayoutMain from '@/components/layout//LayoutMain.vue';

    import { useUiStore } from '@/stores/ui';

    import { onMounted } from 'vue';
    import { storeToRefs } from 'pinia';

    const uiStore = useUiStore();

    const { isUiLoaded, displayOverview } = storeToRefs(uiStore);

    onMounted(async () => {
        isUiLoaded.value = true;
    });
</script>

<style lang="scss" scoped>
    .rtr-embedded-layout {
        backdrop-filter: blur(0px) opacity(0);

        transition: backdrop-filter
            addSuffix(rtr-transition-duration-short, 1ms);
    }
    .rtr-embedded-layout--overview {
        backdrop-filter: blur(2px) opacity(1);
    }
</style>
