import { isString, isNull } from 'lodash-es';

import type { TApplicativeZoom } from '@/types';

import { ZOOM_LEVELS } from '@/constants';

export const validate = async (applicativeZoom: TApplicativeZoom) => {
    if (isNull(applicativeZoom)) {
        return;
    }

    if (!isString(applicativeZoom)) {
        throw new TypeError(
            `'applicativeZoom' has to be a string. '${applicativeZoom}' is not valid.`
        );
    }

    if (!ZOOM_LEVELS.includes(applicativeZoom)) {
        throw new Error(`The selected zoom level is not available.`);
    }
};
