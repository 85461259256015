<template>
    <div
        id="rtr-layout-footer-container"
        class="rtr-tw-absolute rtr-tw-w-full"
        ref="footer"
    >
        <Transition name="rtr-fade">
            <div
                v-show="displayOverview"
                class="rtr-overview-shadow rtr-tw-absolute rtr-tw-h-full rtr-tw-w-full rtr-tw-z-10"
            />
        </Transition>

        <AccessibilityContainer />
    </div>
</template>

<script setup lang="ts">
    import { ref, onBeforeUnmount } from 'vue';
    import { useResizeObserver } from '@vueuse/core';
    import { storeToRefs } from 'pinia';

    import AccessibilityContainer from '@/modules/overlay/components/accessibility/AccessibilityContainer.vue';

    import { useUiStore } from '@/stores/ui';

    const footer = ref(null);
    const uiStore = useUiStore();

    const { footerHeight, displayOverview } = storeToRefs(uiStore);

    const { stop: stopObserver } = useResizeObserver(footer, entries => {
        const entry = entries[0];
        const { height } = entry.contentRect;

        footerHeight.value = height;
    });

    onBeforeUnmount(() => {
        stopObserver();
    });
</script>

<style lang="scss" scoped>
    #rtr-layout-footer-container {
        height: fit-content;
    }

    .rtr-overview-shadow {
        background-color: root-var(alpha-black-light);
    }

    .rtr-fade-enter-active,
    .rtr-fade-leave-active {
        transition: opacity addSuffix(rtr-transition-duration-short, 1ms) ease;
    }

    .rtr-fade-enter-from,
    .rtr-fade-leave-to {
        opacity: 0;
    }
</style>
