import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';

import { useDataStore } from './data';

import type { ICallbackError } from '@luxottica/rtr-viewer';

export const useErrorsStore = defineStore('errors', () => {
    const error = ref<ICallbackError>(null);
    const errorCode = computed(() => error.value.code);

    watch(error, value => {
        if (value) {
            const dataStore = useDataStore();
            dataStore.forceIdUpdate = false;

            console.error(value.error);
        }
    });

    return { error, errorCode };
});
