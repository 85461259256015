<template>
    <div
        id="rtr-error-container"
        class="rtr-tw-grid rtr-tw-grid-flow-row rtr-tw-content-center rtr-tw-justify-items-center rtr-tw-text-center"
    >
        <p id="rtr-error-text" class="Text-lgBold">
            {{ $t(errorTitle) }}
        </p>
        <button
            v-if="showButton"
            id="rtr-error-button"
            class="Text-lgRegular"
            @click="handleButton"
            :aria-label="$t(errorButton)"
        >
            {{ $t(errorButton) }}
        </button>
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { storeToRefs } from 'pinia';

    import { dispose as disposeRtr } from '@luxottica/rtr-viewer';

    import {
        ERROR_CODE_ASSET_LOADING,
        ERROR_CODE_UPC_NOT_AVAILABLE,
        ERROR_CODE_VALIDATION,
        EXPERIENCE_EMBEDDED,
        TR_ERROR_CODE_TITLE,
        TR_ERROR_CODE_BUTTON,
    } from '@/constants';

    import { useDataStore } from '@/stores/data';
    import { useErrorsStore } from '@/stores/errors';
    import { useUiStore } from '@/stores/ui';

    const dataStore = useDataStore();
    const errorsStore = useErrorsStore();
    const uiStore = useUiStore();

    const { forceIdUpdate } = storeToRefs(dataStore);
    const { errorCode } = storeToRefs(errorsStore);
    const { experience, forceUnmount } = storeToRefs(uiStore);

    const errorTitle = computed(() => TR_ERROR_CODE_TITLE[errorCode.value]);
    const errorButton = computed(() => TR_ERROR_CODE_BUTTON[errorCode.value]);

    const showButton = computed(
        () =>
            !(
                experience.value === EXPERIENCE_EMBEDDED &&
                errorCode.value !== ERROR_CODE_ASSET_LOADING
            )
    );

    const handleButton = () => {
        if (errorCode.value === ERROR_CODE_ASSET_LOADING) {
            disposeRtr();
            forceIdUpdate.value = true;
        } else if (
            errorCode.value === ERROR_CODE_UPC_NOT_AVAILABLE ||
            errorCode.value === ERROR_CODE_VALIDATION
        ) {
            forceUnmount.value = true;
        }
    };
</script>

<style lang="scss" scoped>
    #rtr-error-container {
        background-color: root-var(base-white);
        gap: 10%;
        padding: 10%;
    }

    #rtr-error-text {
        color: root-var(base-text-dark);
    }

    #rtr-error-button {
        min-width: fit-content;
        width: 33%;
        background-color: root-var(base-black);
        color: root-var(base-white);
        letter-spacing: 1px;
        padding: 16px 32px;
        border-radius: 30px;
    }
</style>
