<template>
    <Button
        :disabled="disabled"
        :tooltip="tooltip"
        :ariaLabel="t(TR_TUTORIAL_TOOLTIP)"
        v-model:checked="tutorialButton"
    >
        <TutorialSvg />
    </Button>
</template>

<script setup lang="ts">
    import { TR_TUTORIAL_TOOLTIP } from '@/constants';

    import Button from './Button.vue';

    import { resetCamera } from '@luxottica/rtr-viewer';

    import TutorialSvg from './assets/tutorial.svg';

    import { useAccessibilityStore } from '@/stores/accessibility';
    import { useExplosionStore } from '@/stores/explosion';
    import { useTutorialStore } from '@/stores/tutorial';
    import { useUiStore } from '@/stores/ui';

    import { computed } from 'vue';
    import { useI18n } from 'vue-i18n';

    import type { ITooltip } from '@/interfaces';
    import { storeToRefs } from 'pinia';

    const { t } = useI18n();

    interface IProps {
        disabled?: boolean;
    }

    defineProps<IProps>();

    const accessibilityStore = useAccessibilityStore();
    const explosionStore = useExplosionStore();
    const tutorialStore = useTutorialStore();
    const uiStore = useUiStore();

    const { displayOverview, isLandscape, isMenuActive, experience } =
        storeToRefs(uiStore);

    const tooltip = computed(() => {
        const text = displayOverview.value ? t(TR_TUTORIAL_TOOLTIP) : null;
        const position = isMenuActive.value
            ? isLandscape.value
                ? 'bottom'
                : 'left'
            : 'right';

        const tooltip: ITooltip = {
            text,
            position,
        };

        return tooltip;
    });

    const tutorialButton = computed({
        get() {
            const isActive = tutorialStore.isActive;

            return isActive;
        },
        async set(checked) {
            if (experience.value === 'embedded') {
                experience.value = 'overlay';
            }

            await resetFeatures();

            tutorialStore.isActive = checked;

            resetCamera();
        },
    });

    const resetFeatures = async () => {
        if (explosionStore.isActive) {
            await explosionStore.toggleExplosion();

            explosionStore.isActive = false;
        }

        accessibilityStore.isActive = false;
        uiStore.isMenuActive = false;
    };
</script>

<style lang="scss" scoped></style>
