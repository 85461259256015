<template>
    <button
        class="rtr-tutorial-button rtr-tw-h-full rtr-tw-w-full"
        :aria-label="ariaLabel"
    >
        <slot />
    </button>
</template>

<script setup lang="ts">
    import { computed } from 'vue';

    import { TR_TUTORIAL_BACK, TR_TUTORIAL_NEXT } from '@/constants';

    import { useI18n } from 'vue-i18n';

    const { t } = useI18n();

    interface IProps {
        name?: 'back' | 'next';
    }

    const props = defineProps<IProps>();

    const ariaLabel = computed(() => {
        if (props.name === 'back') {
            return t(TR_TUTORIAL_BACK);
        }

        if (props.name === 'next') {
            return t(TR_TUTORIAL_NEXT);
        }

        return '';
    });
</script>

<style lang="scss" scoped>
    .rtr-tutorial-button {
        border-radius: 50%;
        background-color: root-var(base-primary);

        // &:hover {
        //     background-color: $clr-blue-2;
        // }
    }

    :slotted(*) {
        margin: auto;
        transform: scale(calc(var(--rtr-applicative-zoom) / 100));

        & :deep(*) {
            fill: root-var(base-white);
        }
    }
</style>
