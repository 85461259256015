<template>
    <Button
        :disabled="disabled"
        :tooltip="tooltip"
        :ariaLabel="t(TR_ACCESSIBILITY_TOOLTIP)"
        v-model:checked="accessibilityButton"
    >
        <AccessibilitySvg />
    </Button>
</template>

<script setup lang="ts">
    import { TR_ACCESSIBILITY_TOOLTIP } from '@/constants';

    import Button from './Button.vue';

    import AccessibilitySvg from './assets/accessibility.svg';

    import { useAccessibilityStore } from '@/stores/accessibility';
    import { useUiStore } from '@/stores/ui';

    import { computed, nextTick } from 'vue';
    import { storeToRefs } from 'pinia';
    import { useI18n } from 'vue-i18n';

    import { resetCamera } from '@luxottica/rtr-viewer';

    import type { ITooltip } from '@/interfaces';

    const { t } = useI18n();

    interface IProps {
        disabled?: boolean;
    }

    defineProps<IProps>();

    const accessibilityStore = useAccessibilityStore();
    const uiStore = useUiStore();

    const { experience, isLandscape, displayOverview, isMenuActive } =
        storeToRefs(uiStore);

    const tooltip = computed(() => {
        const text = displayOverview.value ? t(TR_ACCESSIBILITY_TOOLTIP) : null;

        const position = isMenuActive.value
            ? isLandscape.value
                ? 'bottom'
                : 'left'
            : 'right';

        const tooltip: ITooltip = {
            text,
            position,
        };

        return tooltip;
    });

    const accessibilityButton = computed({
        get() {
            const isActive = accessibilityStore.isActive;

            return isActive;
        },
        async set(checked) {
            if (experience.value === 'embedded') {
                experience.value = 'overlay';

                await nextTick();
                resetCamera();
            }

            accessibilityStore.isActive = checked;
        },
    });
</script>

<style lang="scss" scoped></style>
