import type { IAccessibilityButton } from '@/interfaces';

import {
    TR_ACCESSIBILITY_BUTTON_MOVE_DOWN,
    TR_ACCESSIBILITY_BUTTON_MOVE_RIGHT,
    TR_ACCESSIBILITY_BUTTON_MOVE_LEFT,
    TR_ACCESSIBILITY_BUTTON_MOVE_UP,
    TR_ACCESSIBILITY_BUTTON_ROTATE_DOWN,
    TR_ACCESSIBILITY_BUTTON_ROTATE_LEFT,
    TR_ACCESSIBILITY_BUTTON_ROTATE_RIGHT,
    TR_ACCESSIBILITY_BUTTON_ROTATE_UP,
    TR_ACCESSIBILITY_BUTTON_ZOOM_IN,
    TR_ACCESSIBILITY_BUTTON_ZOOM_OUT,
} from './translations';

const TRUCK_RIGHT_BUTTON: IAccessibilityButton = {
    name: 'truckRight',
    translation: TR_ACCESSIBILITY_BUTTON_MOVE_RIGHT,
    action: {
        function: 'truck',
        settings: [-0.01, 0],
    },
    icon: {
        name: 'arrow',
        class: 'rtr-tw-rotate-90',
    },
};

const TRUCK_UP_BUTTON: IAccessibilityButton = {
    name: 'truckUp',
    translation: TR_ACCESSIBILITY_BUTTON_MOVE_UP,
    action: {
        function: 'truck',
        settings: [0, 0.01],
    },
    icon: {
        name: 'arrow',
    },
};

const TRUCK_LEFT_BUTTON: IAccessibilityButton = {
    name: 'truckLeft',
    translation: TR_ACCESSIBILITY_BUTTON_MOVE_LEFT,
    action: {
        function: 'truck',
        settings: [0.01, 0],
    },
    icon: {
        name: 'arrow',
        class: 'rtr-tw--rotate-90',
    },
};

const TRUCK_DOWN_BUTTON: IAccessibilityButton = {
    name: 'truckDown',
    translation: TR_ACCESSIBILITY_BUTTON_MOVE_DOWN,
    action: {
        function: 'truck',
        settings: [0, -0.01],
    },
    icon: {
        name: 'arrow',
        class: 'rtr-tw-rotate-180',
    },
};

const ROTATE_LEFT_BUTTON: IAccessibilityButton = {
    name: 'rotateLeft',
    translation: TR_ACCESSIBILITY_BUTTON_ROTATE_LEFT,
    action: {
        function: 'rotate',
        settings: [0.17, 0],
    },
    icon: {
        name: 'rotate',
        class: 'rtr-tw--scale-x-75 rtr-tw-scale-y-75',
    },
};

const ROTATE_RIGHT_BUTTON: IAccessibilityButton = {
    name: 'rotateRight',
    translation: TR_ACCESSIBILITY_BUTTON_ROTATE_RIGHT,
    action: {
        function: 'rotate',
        settings: [-0.17, 0],
    },
    icon: {
        name: 'rotate',
        class: ' rtr-tw-scale-75',
    },
};

const ROTATE_DOWN_BUTTON: IAccessibilityButton = {
    name: 'rotateDown',
    translation: TR_ACCESSIBILITY_BUTTON_ROTATE_DOWN,
    action: {
        function: 'rotate',
        settings: [0, -0.17],
    },
    icon: {
        name: 'rotate',
        class: 'rtr-tw-rotate-90 rtr-tw-scale-75',
    },
};

const ROTATE_UP_BUTTON: IAccessibilityButton = {
    name: 'rotateUp',
    translation: TR_ACCESSIBILITY_BUTTON_ROTATE_UP,
    action: {
        function: 'rotate',
        settings: [0, 0.17],
    },
    icon: {
        name: 'rotate',
        class: 'rtr-tw-rotate-90 rtr-tw--scale-x-75 rtr-tw-scale-y-75',
    },
};

const DOLLY_IN_BUTTON: IAccessibilityButton = {
    name: 'dollyIn',
    translation: TR_ACCESSIBILITY_BUTTON_ZOOM_IN,
    action: {
        function: 'dolly',
        settings: [0.1],
    },
    icon: {
        name: 'dolly-in',
    },
};

const DOLLY_OUT_BUTTON: IAccessibilityButton = {
    name: 'dollyOut',
    translation: TR_ACCESSIBILITY_BUTTON_ZOOM_OUT,
    action: {
        function: 'dolly',
        settings: [-0.1],
    },
    icon: {
        name: 'dolly-out',
        class: 'rtr-tw-px-2',
    },
};

export const ACCESSIBILITY_AVAILABLE_BUTTONS = [
    TRUCK_RIGHT_BUTTON,
    TRUCK_UP_BUTTON,
    TRUCK_LEFT_BUTTON,
    TRUCK_DOWN_BUTTON,
    ROTATE_LEFT_BUTTON,
    ROTATE_RIGHT_BUTTON,
    ROTATE_DOWN_BUTTON,
    ROTATE_UP_BUTTON,
    DOLLY_IN_BUTTON,
    DOLLY_OUT_BUTTON,
];
