import { isString } from 'lodash-es';

import { ANIMATIONS_AT_LANDING_VALUES } from '@/constants';

import type { TAnimationAtLanding } from '@/types';

export const validate = async (animation: TAnimationAtLanding) => {
    if (!isString(animation)) {
        throw new TypeError(
            `'animationAtLanding' has to be a string. '${animation}' is not valid: falling back to default animation`
        );
    }

    if (!ANIMATIONS_AT_LANDING_VALUES.includes(animation)) {
        throw new Error(
            `The selected animation at landing is not available: falling back to default animation`
        );
    }
};
