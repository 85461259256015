import { defineStore, storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

import type { TExperience } from '@/types';

import { useAccessibilityStore } from '@/stores/accessibility';
import { useZoomStore } from '@/stores/zoom';

export const useUiStore = defineStore('ui', () => {
    const experience: TExperience = ref(null);
    const initialExperience: TExperience = ref(null);

    const forceUnmount = ref(false);
    const disableRtrInteraction = ref(false);

    const isRtrLoaded = ref(false);
    const isUiLoaded = ref(false);

    const displayOverview = ref(false);

    const footerHeight = ref(0);

    const isLandscape = ref(null);
    const isHigherThan600 = ref(null);

    const isMenuActive = ref(false);

    const displayFullscreenTutorial = computed(() => {
        const zoomStore = useZoomStore();
        const { zoomBoundary } = storeToRefs(zoomStore);

        return (
            zoomBoundary.value !== '100' &&
            (!isHigherThan600.value || isLandscape.value)
        );
    });

    const showFooter = computed(() => {
        const accessibilityStore = useAccessibilityStore();
        const isAccessibilityActive = accessibilityStore.isActive;

        const showFooter = isAccessibilityActive;

        const height = showFooter ? footerHeight.value : 0;

        document.documentElement.style.setProperty(
            '--rtr-footer-height',
            height + 'px'
        );

        return showFooter;
    });

    watch(isMenuActive, value => {
        displayOverview.value = value;
    });

    return {
        disableRtrInteraction,
        displayFullscreenTutorial,
        displayOverview,
        experience,
        footerHeight,
        forceUnmount,
        initialExperience,
        isHigherThan600,
        isLandscape,
        isRtrLoaded,
        isMenuActive,
        isUiLoaded,
        showFooter,
    };
});
