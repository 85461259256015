<template>
    <ul
        class="rtr-tw-flex rtr-tw-items-center rtr-tw-justify-center rtr-tw-gap-4 rtr-tw-whitespace-nowrap"
    >
        <li v-for="(step, index) in TUTORIAL_STEPS" :key="index">
            <button
                class="rtr-tutorial-dot"
                :class="{
                    'rtr-tutorial-dot-active': activeStepIndex === index,
                }"
                :aria-label="$t(step.step)"
                @click="handleClick(index)"
            />
        </li>
    </ul>
</template>

<script setup lang="ts">
    import { TUTORIAL_STEPS } from '@/constants';

    import { useTutorialStore } from '@/stores/tutorial';
    import { storeToRefs } from 'pinia';

    const tutorialStore = useTutorialStore();

    const { activeStepIndex } = storeToRefs(tutorialStore);

    const handleClick = (index: number) => {
        activeStepIndex.value = index;
    };
</script>

<style lang="scss" scoped>
    .rtr-tutorial-dot {
        --size: 12px;

        height: calc(var(--rtr-applicative-zoom) / 100 * var(--size));
        width: calc(var(--rtr-applicative-zoom) / 100 * var(--size));

        border-radius: 50%;
        background-color: root-var(base-gray-light);

        transition-duration: addSuffix(rtr-transition-duration-very-short, 1ms);
    }

    .rtr-tutorial-dot-active {
        background-color: root-var(base-text-dark);
    }
</style>
