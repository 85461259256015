import type { TApplicativeZoom } from '@/types';

export const ZOOM_LEVELS: TApplicativeZoom[] = [
    '100',
    '110',
    '125',
    '150',
    '175',
    '200',
];
