type TTranslationsApiEnvironment = 'dev' | 'test' | 'prod';

import {
    ENVIRONMENT_DEV,
    ENVIRONMENT_PROD,
    ENVIRONMENT_UAT,
} from '../environments';

const host = 'https://d5nhc6q3o19l2.cloudfront.net/';

const API_ENVIRONMENT_DEV: TTranslationsApiEnvironment = 'dev';
const API_ENVIRONMENT_UAT: TTranslationsApiEnvironment = 'test';
const API_ENVIRONMENT_PROD: TTranslationsApiEnvironment = 'prod';

const API_ENVIRONMENT = {
    [ENVIRONMENT_DEV]: API_ENVIRONMENT_DEV,
    [ENVIRONMENT_UAT]: API_ENVIRONMENT_UAT,
    [ENVIRONMENT_PROD]: API_ENVIRONMENT_PROD,
};

export const API_TRANSLATIONS_URL = `${host}rtr-mv/v1/${API_ENVIRONMENT[ENVIRONMENT]}/application/`;
