<template>
    <Button
        :disabled="disabled"
        :pending="pending"
        :tooltip="tooltip"
        :ariaLabel="t(TR_EXPLOSION_TOOLTIP)"
        v-model:checked="explosionButton"
    >
        <ExplosionSvg />
    </Button>
</template>

<script setup lang="ts">
    import { TR_EXPLOSION_TOOLTIP } from '@/constants';

    import Button from './Button.vue';

    import ExplosionSvg from './assets/explosion.svg?component';

    import { useExplosionStore } from '@/stores/explosion';
    import { useUiStore } from '@/stores/ui';

    import { computed, nextTick } from 'vue';
    import { storeToRefs } from 'pinia';
    import { useI18n } from 'vue-i18n';

    import type { ITooltip } from '@/interfaces';

    import { resetCamera } from '@luxottica/rtr-viewer';

    const { t } = useI18n();

    interface IProps {
        disabled?: boolean;
        pending?: boolean;
    }

    defineProps<IProps>();

    const explosionStore = useExplosionStore();
    const uiStore = useUiStore();

    const { displayOverview, isLandscape, isMenuActive, experience } =
        storeToRefs(uiStore);

    const tooltip = computed(() => {
        const text = displayOverview.value ? t(TR_EXPLOSION_TOOLTIP) : null;

        const position = isMenuActive.value
            ? isLandscape.value
                ? 'bottom'
                : 'left'
            : 'right';

        const tooltip: ITooltip = {
            text,
            position,
        };

        return tooltip;
    });

    const explosionButton = computed({
        get() {
            const isActive = explosionStore.isActive;

            return isActive;
        },
        async set(checked) {
            if (experience.value === 'embedded') {
                experience.value = 'overlay';

                await nextTick();
                resetCamera();
            }

            explosionStore.isActive = checked;

            explosionStore.toggleExplosion();
        },
    });
</script>

<style lang="scss" scoped></style>
