<template>
    <Button
        :disabled="disabled || isRotationActive"
        :pending="isRotationActive"
        :tooltip="tooltip"
        :ariaLabel="t('animation.tooltip')"
        @click="handleRotate"
    >
        <RotateSvg />
    </Button>
</template>

<script setup lang="ts">
    import Button from './Button.vue';

    import RotateSvg from './assets/rotate.svg';

    import { useAnimationsStore } from '@/stores/animations';
    import { useUiStore } from '@/stores/ui';

    import { computed } from 'vue';
    import { storeToRefs } from 'pinia';

    import { useI18n } from 'vue-i18n';

    import type { ITooltip } from '@/interfaces';

    const { t } = useI18n();

    interface IProps {
        disabled?: boolean;
    }

    defineProps<IProps>();

    const animationsStore = useAnimationsStore();
    const uiStore = useUiStore();

    const { isRotationActive } = storeToRefs(animationsStore);

    const tooltip = computed(() => {
        const text = uiStore.isMenuActive ? t('animation.tooltip') : null;

        const position = uiStore.isMenuActive
            ? uiStore.isLandscape
                ? 'bottom'
                : 'left'
            : 'right';

        const tooltip: ITooltip = {
            text,
            position,
        };

        return tooltip;
    });

    const handleRotate = async () => {
        await animationsStore.toggleRotation();
    };
</script>

<style lang="scss" scoped></style>
