import { defineStore } from 'pinia';
import { createI18n } from 'vue-i18n';

import to from 'await-to-js';
import axios from 'axios';

import { useCallbacksStore } from './callbacks';
import { useErrorsStore } from './errors';

import { ICallbackError } from '@luxottica/rtr-viewer';

import {
    API_TRANSLATIONS_URL,
    ERROR_CODE_VALIDATION,
    ERROR_CONTEXT_INITIALIZATION,
} from '@/constants';

export const useTranslationsStore = defineStore('translations', () => {
    let i18n: any;

    const setupI18n = async (locale: string) => {
        i18n = createI18n({
            locale: locale,
            globalInjection: true,
        });

        await setLanguage(locale);

        return i18n;
    };

    const setLanguage = async (locale: string) => {
        i18n.global.locale.value = locale;

        const [err, response] = await to(
            axios.get(`${API_TRANSLATIONS_URL}application_${locale}.json`)
        );

        if (err) {
            const callbacksStore = useCallbacksStore();
            const errorsStore = useErrorsStore();

            const error: ICallbackError = {
                code: ERROR_CODE_VALIDATION,
                context: ERROR_CONTEXT_INITIALIZATION,
                error: err,
            };

            errorsStore.error = error;
            callbacksStore.onError(error);

            return;
        }

        const messages = response.data;

        i18n.global.setLocaleMessage(locale, messages);
    };

    return {
        setupI18n,
        setLanguage,
    };
});
