<template>
    <ButtonsContainer
        class="rtr-tw-absolute rtr-tw-bottom-0 rtr-tw-right-0 rtr-tw-pb-6 rtr-tw-pr-5"
        :class="{
            'rtr-animation-block--overview': displayOverview && !isMenuActive,
        }"
    >
        <TransitionGroup name="rtr-fade">
            <ButtonsContainer v-if="isMenuActive && !isLandscape">
                <ButtonExplosion @click="isMenuActive = false" />
                <ButtonAccessibility @click="isMenuActive = false" />
                <ButtonTutorial @click="isMenuActive = false" />
                <ButtonRotate @click="isMenuActive = false" />
            </ButtonsContainer>
        </TransitionGroup>

        <ButtonMenu
            class="rtr-tw-flex-row-reverse"
            :disabled="isMenuDisabled"
        />
    </ButtonsContainer>
</template>

<script setup lang="ts">
    import ButtonsContainer from '@/components/buttons/ButtonsContainer.vue';
    import ButtonAccessibility from '@/components/buttons/ButtonAccessibility.vue';
    import ButtonExplosion from '@/components/buttons/ButtonExplosion.vue';
    import ButtonRotate from '@/components/buttons/ButtonRotate.vue';
    import ButtonTutorial from '@/components/buttons/ButtonTutorial.vue';
    import ButtonMenu from '@/components/buttons/ButtonMenu.vue';

    import { useTutorialStore } from '@/stores/tutorial';
    import { useUiStore } from '@/stores/ui';

    import { computed } from 'vue';
    import { storeToRefs } from 'pinia';

    const tutorialStore = useTutorialStore();
    const uiStore = useUiStore();

    const { isActive: isTutorialActive } = storeToRefs(tutorialStore);
    const { displayOverview, isLandscape, isMenuActive } = storeToRefs(uiStore);

    const isMenuDisabled = computed(() => {
        if (displayOverview.value && !isMenuActive.value) {
            return true;
        }

        if (isTutorialActive.value) {
            return true;
        }

        return false;
    });
</script>

<style lang="scss" scoped>
    .rtr-animation-block--overview {
        filter: blur(2px);
    }

    .rtr-fade-enter-active,
    .rtr-fade-leave-active {
        transition: opacity addSuffix(rtr-transition-duration-short, 1ms) ease;
    }

    .rtr-fade-enter-from,
    .rtr-fade-leave-to {
        opacity: 0;
    }
</style>
