import { defineStore, acceptHMRUpdate } from 'pinia';
import { ref } from 'vue';

import type { TActionsStatus } from '@luxottica/rtr-viewer';

import { ACTION_STATUS_INITIALS } from '@/constants';

export const useActionsStore = defineStore('actions', () => {
    const actionsStatus = ref<TActionsStatus>(ACTION_STATUS_INITIALS);

    return { actionsStatus };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useActionsStore, import.meta.hot));
}
