import { createApp, watch } from 'vue';
import { createPinia, storeToRefs } from 'pinia';

import {
    init as initRtr,
    getVersion as getRtrVersion,
    isInitialized as isRtrInitialized,
} from '@luxottica/rtr-viewer';

import to from 'await-to-js';

import App from '@/components/App.vue';

// Constants
import {
    DEFAULT_ANIMATION_AT_LANDING,
    DEFAULT_CALLBACK,
    DEFAULT_OVERLAY_SELECTOR,
    DEFAULT_RTR_SETTINGS_AUTO_RESET_CAMERA,
    DEFAULT_RTR_SETTINGS_BACKGROUND_FIT,
    DEFAULT_RTR_SETTINGS_BASE_COLOR,
    DEFAULT_RTR_SETTINGS_CAMERA_ANIMATION_TIME,
    DEFAULT_RTR_SETTINGS_CAMERA_ROTATION_INITIAL,
    DEFAULT_RTR_SETTINGS_CAMERA_ROTATION_RESET,
    DEFAULT_RTR_SETTINGS_LENSES_NAME,
    DEFAULT_RTR_SETTINGS_LENSES_OPACITY,
    DEFAULT_RTR_SETTINGS_SELECTOR,
    DEFAULT_RTR_SETTINGS_SHOW_BACKGROUND,
    ERROR_CODE_VALIDATION,
    ERROR_CONTEXT_INITIALIZATION,
    EXPERIENCE_EMBEDDED,
    EXPERIENCE_OVERLAY,
} from '@/constants';

// Interfaces
import type { IInitInput, IInitCallbacks } from '@/interfaces';
import type {
    ICallbackActions,
    ICallbackError,
    ICallbackFocus,
    IId,
} from '@luxottica/rtr-viewer';

// Types
import type { TApplicativeZoom, TExperience } from '@/types';

// Validators
import {
    validateAnimations,
    validateLocale,
    validateSelector,
} from '@/validators';

// Stores
import { useActionsStore } from '@/stores/actions';
import { useAnimationsStore } from '@/stores/animations';
import { useCallbacksStore } from '@/stores/callbacks';
import { useDataStore } from '@/stores/data';
import { useErrorsStore } from '@/stores/errors';
import { useFocusStore } from '@/stores/focus';
import { useTranslationsStore } from '@/stores/translations';
import { useUiStore } from '@/stores/ui';
import { useZoomStore } from '@/stores/zoom';

const supportsContainerQueries = 'container' in document.documentElement.style;

if (!supportsContainerQueries) {
    // @ts-ignore
    import('container-query-polyfill');
}

const handleRtrOnActions = (details: ICallbackActions) => {
    const { actions } = details;

    const actionsStore = useActionsStore();

    actionsStore.actionsStatus = actions;
};

const handleRtrOnError = (err: ICallbackError) => {
    const callbacksStore = useCallbacksStore();
    const errorsStore = useErrorsStore();

    errorsStore.error = err;

    callbacksStore.onError(err);
};

const handleRtrOnFocus = (details: ICallbackFocus) => {
    const { focus } = details;

    const focusStore = useFocusStore();

    focusStore.isFocus = focus;
};

const handleRtrOnRendered = () => {
    const uiStore = useUiStore();

    uiStore.isRtrLoaded = true;
};

const initCallbacks = (callbacks: IInitCallbacks = {}) => {
    const { onClose, onError } = callbacks;

    const callbacksStore = useCallbacksStore();

    callbacksStore.onClose = onClose;
    callbacksStore.onError = onError;
};

const initCustomRtr = (id: IId) => {
    const animationsStore = useAnimationsStore();

    const { animationAtLandingSettings } = storeToRefs(animationsStore);

    // const clearColor = getComputedStyle(
    //     document.documentElement
    // ).getPropertyValue('--base-gray-ultra-light');

    const cameraRotationInitial =
        animationAtLandingSettings.value.initialPosition ??
        DEFAULT_RTR_SETTINGS_CAMERA_ROTATION_INITIAL;

    initRtr({
        data: {
            id,
            selector: DEFAULT_RTR_SETTINGS_SELECTOR,
            settings: {
                autoResetCamera: DEFAULT_RTR_SETTINGS_AUTO_RESET_CAMERA,
                backgroundFit: DEFAULT_RTR_SETTINGS_BACKGROUND_FIT,
                cameraAnimationTime: DEFAULT_RTR_SETTINGS_CAMERA_ANIMATION_TIME,
                cameraRotationInitial,
                cameraRotationReset: DEFAULT_RTR_SETTINGS_CAMERA_ROTATION_RESET,
                clearColor: DEFAULT_RTR_SETTINGS_BASE_COLOR,
                lensesName: DEFAULT_RTR_SETTINGS_LENSES_NAME,
                lensesOpacity: DEFAULT_RTR_SETTINGS_LENSES_OPACITY,
                showBackground: DEFAULT_RTR_SETTINGS_SHOW_BACKGROUND,
            },
        },
        metadata: {
            env: RTR_ENVIRONMENT,
        },
        callbacks: {
            onActions: handleRtrOnActions,
            onError: handleRtrOnError,
            onFocus: handleRtrOnFocus,
            onRendered: handleRtrOnRendered,
        },
    });
};

const initUi = (experience: TExperience) => {
    const uiStore = useUiStore();

    uiStore.experience = experience;
    uiStore.initialExperience = experience;
};

export const init = async ({
    data: {
        id,
        locale,
        selector,
        settings: {
            animationAtLanding = DEFAULT_ANIMATION_AT_LANDING,
            applicativeZoom,
        } = {},
    },

    callbacks: { onClose = DEFAULT_CALLBACK, onError = DEFAULT_CALLBACK } = {},
}: IInitInput) => {
    if (isRtrInitialized()) return;

    let err: Error;

    const app = createApp(App);
    const pinia = createPinia();

    app.use(pinia);

    const uiStore = useUiStore();
    const { forceUnmount } = storeToRefs(uiStore);

    initCallbacks({ onClose, onError });

    [err] = await to(
        Promise.all([validateLocale(locale), validateSelector(selector)])
    );

    if (err) {
        const callbacksStore = useCallbacksStore();
        const errorsStore = useErrorsStore();

        const error: ICallbackError = {
            code: ERROR_CODE_VALIDATION,
            context: ERROR_CONTEXT_INITIALIZATION,
            error: err,
        };

        errorsStore.error = error;
        callbacksStore.onError(error);

        return;
    }

    [err] = await to(Promise.all([validateAnimations(animationAtLanding)]));

    if (err) {
        console.warn(err.message);
    }

    console.group('RTR viewer Master Version');
    console.log('RTR viewer version: ' + getRtrVersion());
    console.log(`ID: ${id.value} (${id.type})`);
    console.log('Environment: ' + ENVIRONMENT);
    console.log('RTR environment: ' + RTR_ENVIRONMENT);
    console.groupEnd();

    // Locale
    const translationsStore = useTranslationsStore();
    const i18n = await translationsStore.setupI18n(locale);

    app.use(i18n);

    // Animations
    const animationsStore = useAnimationsStore();
    animationsStore.animationAtLanding = animationAtLanding;

    // ApplicativeZoom
    setApplicativeZoom(applicativeZoom);

    // Experience
    const experience: TExperience = selector
        ? EXPERIENCE_EMBEDDED
        : EXPERIENCE_OVERLAY;

    initUi(experience);

    if (experience === EXPERIENCE_OVERLAY) {
        const overlayContainer = document.createElement('div');
        overlayContainer.id = DEFAULT_OVERLAY_SELECTOR;
        document.body.appendChild(overlayContainer);

        selector = `#${DEFAULT_OVERLAY_SELECTOR}`;
    }

    app.mount(selector);

    initCustomRtr(id);

    // Watchers

    watch(forceUnmount, value => {
        if (value) {
            app.unmount();

            const overlayContainer = document.getElementById(
                DEFAULT_OVERLAY_SELECTOR
            );

            if (!overlayContainer) {
                return;
            }

            overlayContainer.remove();
        }
    });
};

// ACTIONS

export const dispose = () => {
    if (!isRtrInitialized()) return;

    const uiStore = useUiStore();

    uiStore.forceUnmount = true;
};

// GETTERS

export { isIdAvailable, isInitialized } from '@luxottica/rtr-viewer';

export const getVersion = () => {
    return VITE_LIB_VERSION;
};

// SETTERS

export const setId = (id: IId) => {
    if (!isRtrInitialized()) return;

    const dataStore = useDataStore();
    const errorsStore = useErrorsStore();

    dataStore.updateId(id);

    dataStore.forceIdUpdate = true;
    errorsStore.error = null;

    initCustomRtr(id);
};

export const setApplicativeZoom = (zoom: TApplicativeZoom) => {
    if (!(isRtrInitialized() && zoom)) return;

    const zoomStore = useZoomStore();
    zoomStore.setApplicativeZoom(zoom);
};
