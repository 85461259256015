<template>
    <div
        class="rtr-tw-relative rtr-tw-flex rtr-tw-items-end rtr-tw-justify-center"
        :class="{ 'rtr-tutorial-container--zoomed': displayFullscreenTutorial }"
    >
        <TutorialAnimation
            v-if="isTouchDevice() && !displayFullscreenTutorial"
        />

        <div
            id="rtr-tutorial-shadow"
            class="rtr-tw-absolute rtr-tw-h-full rtr-tw-w-full"
            v-if="!displayFullscreenTutorial"
        ></div>

        <TutorialBox
            id="rtr-tutorial-box"
            class="rtr-tw-pointer-events-auto rtr-tw-w-full"
            :class="{
                'rtr-tutorial-box--zoomed !rtr-tw-m-0 rtr-tw-h-full !rtr-tw-max-w-full':
                    displayFullscreenTutorial,
            }"
        />
    </div>
</template>

<script setup lang="ts">
    import TutorialAnimation from './TutorialAnimation.vue';
    import TutorialBox from './TutorialBox.vue';

    import { isTouchDevice } from '@luxottica/rtr-viewer';

    import { useUiStore } from '@/stores/ui';
    import { storeToRefs } from 'pinia';

    const uiStore = useUiStore();

    const { displayFullscreenTutorial } = storeToRefs(uiStore);
</script>

<style lang="scss" scoped>
    #rtr-tutorial-shadow {
        height: calc(100% + root-var(rtr-tutorial-height));
        transition: height addSuffix(rtr-transition-duration, 1ms) ease;

        background: radial-gradient(
            60% 40vw at 50% 50%,
            transparent 65%,
            rgba(0, 0, 0, 0.3) 85%
        );
    }

    #rtr-tutorial-box {
        z-index: 1;

        background-color: root-var(base-white);

        max-width: $tutorial-max-width;
        padding: 10px;

        margin: 0 10% 5%;

        border-radius: 20px;
    }

    .rtr-tutorial-container--zoomed {
        padding: 20px;
    }

    .rtr-tutorial-box--zoomed {
        box-shadow: 0 0 0 100vh rgba(0, 0, 0, 0.3);
    }
</style>
