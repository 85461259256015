import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import to from 'await-to-js';

import { validateApplicativeZoom } from '@/validators';

import type { TApplicativeZoom, TApplicativeZoomBoundaries } from '@/types';

export const useZoomStore = defineStore('zoom', () => {
    const applicativeZoom = ref<TApplicativeZoom | null>(null);

    const setApplicativeZoom = async (zoom: TApplicativeZoom) => {
        const [err] = await to(validateApplicativeZoom(zoom));

        if (err) {
            console.warn(err.message);
            return;
        }

        document.documentElement.style.setProperty(
            '--rtr-applicative-zoom',
            zoom
        );

        applicativeZoom.value = zoom;
    };

    const zoomBoundary = computed<TApplicativeZoomBoundaries>(() => {
        const zoomValue = Number(applicativeZoom.value);

        if (zoomValue > 150) {
            return '200';
        }

        if (zoomValue > 100 && zoomValue <= 150) {
            return '150';
        }

        return '100';
    });

    return {
        applicativeZoom,
        zoomBoundary,
        setApplicativeZoom,
    };
});
