<template>
    <button class="rtr-accessibility-control" :aria-label="ariaLabel">
        <ArrowIcon
            :class="{ 'rtr-tw-rotate-180': type === 'back' }"
            class="rtr-accessibility-control-icon rtr-tw-mx-auto rtr-tw-my-0"
        />
    </button>
</template>

<script setup lang="ts">
    import ArrowIcon from './assets/arrow-bold.svg?component';

    import { computed } from 'vue';

    import { TR_ACCESSIBILITY_BACK, TR_ACCESSIBILITY_NEXT } from '@/constants';

    import { useI18n } from 'vue-i18n';

    const { t } = useI18n();

    type TType = 'back' | 'next';

    interface IProps {
        type: TType;
    }

    const props = defineProps<IProps>();

    const ariaLabel = computed(() => {
        if (props.type === 'back') {
            return t(TR_ACCESSIBILITY_BACK);
        }

        if (props.type === 'next') {
            return t(TR_ACCESSIBILITY_NEXT);
        }

        return '';
    });
</script>

<style lang="scss" scoped>
    .rtr-accessibility-control {
        --size: 56px;

        height: calc(var(--rtr-applicative-zoom) / 100 * var(--size));
        width: calc(var(--rtr-applicative-zoom) / 100 * var(--size));

        background-color: root-var(base-black);
        color: root-var(base-white);
    }

    .rtr-accessibility-control-icon {
        scale: calc(var(--rtr-applicative-zoom) / 100);

        & :deep(*) {
            fill: root-var(base-white);
        }
    }
</style>
