import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import {
    ANIMATIONS_AT_LANDING,
    ANIMATION_AT_LANDING_FALLBACK,
    DEFAULT_RTR_SETTINGS_CAMERA_ROTATION_RESET,
    DEFAULT_ANIMATION_AT_LANDING,
    DEFAULT_ANIMATION_AT_LANDING_TIME,
} from '@/constants';

import { asyncTimeout } from '@/utils/promises';
import { rotate } from '@/scripts/animations';
import { setCameraRotationReset, resetCamera } from '@luxottica/rtr-viewer';

import type { TAnimationAtLanding } from '@/types';

export const useAnimationsStore = defineStore('animations', () => {
    const isRotationActive = ref(false);
    const displayAnimationAtLanding = ref(false);

    const animationAtLanding = ref<TAnimationAtLanding>(
        DEFAULT_ANIMATION_AT_LANDING
    );

    const animationAtLandingSettings = computed(() => {
        const animation = ANIMATIONS_AT_LANDING.find(
            settings => settings.name === animationAtLanding.value
        );

        return animation ?? ANIMATION_AT_LANDING_FALLBACK;
    });

    const toggleAnimationAtLanding = async () => {
        displayAnimationAtLanding.value = true;

        await toggleRotation(true);

        displayAnimationAtLanding.value = false;
    };

    const toggleRotation = async (isAtLanding = false) => {
        const time = DEFAULT_ANIMATION_AT_LANDING_TIME;

        const { angle, initialPosition, withZoom } =
            animationAtLandingSettings.value;

        isRotationActive.value = true;

        if (!isAtLanding) {
            if (initialPosition) {
                setCameraRotationReset(initialPosition);

                await asyncTimeout(100);
            }

            resetCamera();

            await asyncTimeout(1000);

            setCameraRotationReset(DEFAULT_RTR_SETTINGS_CAMERA_ROTATION_RESET);
        }

        await rotate({ angle, time, withZoom });

        await asyncTimeout(time);

        isRotationActive.value = false;
    };

    return {
        isRotationActive,
        displayAnimationAtLanding,
        animationAtLanding,
        animationAtLandingSettings,
        toggleAnimationAtLanding,
        toggleRotation,
    };
});
