import type { TAnimationAtLanding, TApplicativeZoom } from '@/types';
import type {
    TEnvironment as TRtrEnvironment,
    TCameraRotation as TRtrCameraRotation,
    TFit as TRtrFit,
} from '@luxottica/rtr-viewer';

// RTR MV
export const DEFAULT_CALLBACK = () => {};
export const DEFAULT_EMBEDDED_OVERVIEW_TIME = 3 * 1000; // milliseconds
export const DEFAULT_OVERLAY_SELECTOR = 'rtr-overlay-container';
export const DEFAULT_ANIMATION_AT_LANDING_TIME = 3 * 1000; // milliseconds
export const DEFAULT_ANIMATION_AT_LANDING: TAnimationAtLanding = '90-with-zoom';
export const DEFAULT_APPLICATIVE_ZOOM: TApplicativeZoom = '100';

// RTR
export const DEFAULT_RTR_METADATA_ENV: TRtrEnvironment = 'development';

export const DEFAULT_RTR_SETTINGS_AUTO_RESET_CAMERA = false;
export const DEFAULT_RTR_SETTINGS_BACKGROUND_FIT: TRtrFit = 'fill';
export const DEFAULT_RTR_SETTINGS_BASE_COLOR = '#f6f6f6';
export const DEFAULT_RTR_SETTINGS_CAMERA_ANIMATION_TIME = 0.3;
export const DEFAULT_RTR_SETTINGS_CAMERA_ROTATION_INITIAL: TRtrCameraRotation =
    {
        phi: 0,
        theta: 0,
    };
export const DEFAULT_RTR_SETTINGS_CAMERA_ROTATION_RESET: TRtrCameraRotation = {
    phi: 0,
    theta: 18,
};
export const DEFAULT_RTR_SETTINGS_LENSES_NAME = undefined;
export const DEFAULT_RTR_SETTINGS_LENSES_OPACITY = 0;
export const DEFAULT_RTR_SETTINGS_SELECTOR = '#rtr-viewer-container';
export const DEFAULT_RTR_SETTINGS_SHOW_BACKGROUND = false;
