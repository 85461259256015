import { defineStore } from 'pinia';
import { ref } from 'vue';

import { setId } from '@luxottica/rtr-viewer';
import type { IId } from '@luxottica/rtr-viewer';

import { useUiStore } from './ui';

export const useDataStore = defineStore('data', () => {
    const currentId = ref(null);

    const forceIdUpdate = ref(false);

    const updateId = (id: IId) => {
        const uiStore = useUiStore();

        currentId.value = id;

        setId(currentId.value);

        uiStore.isRtrLoaded = false;
        forceIdUpdate.value = true;
    };

    return { currentId, forceIdUpdate, updateId };
});
