import { isString, isUndefined } from 'lodash-es';

import { isSelector } from '@/utils/validation';

export const validate = async (selector?: string) => {
    if (isUndefined(selector)) return;

    if (!isString(selector)) {
        throw new TypeError(
            `'selector' has to be a string. '${selector}' is not valid`
        );
    }

    if (!isSelector(selector)) {
        throw new Error(
            `'selector' has to be a selector. '${selector}' is not valid`
        );
    }

    const element = document.querySelector(selector);

    if (!element) {
        throw new Error(
            `There is no corresponding element for the selector '${selector}'`
        );
    }

    if (!(element instanceof HTMLDivElement)) {
        throw new Error(
            `The corresponding element for the selector '${selector}' is not a <div>`
        );
    }
};
