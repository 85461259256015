<template>
    <Transition name="rtr-fade">
        <Vue3Lottie
            v-if="!isAnimationFinished"
            class="rtr-gesture-animation rtr-tw-absolute"
            :key="lottieKey"
            :animation-data="gestureAnimation"
            :loop="3"
            @onComplete="handleAnimationCompleted"
        ></Vue3Lottie>
    </Transition>
</template>

<script setup lang="ts">
    import {
        TUTORIAL_STEPS,
        TUTORIAL_GESTURES_LOOP,
        TUTORIAL_GESTURES_IDLE_TIME,
        TUTORIAL_IDLE_TIME,
    } from '@/constants';

    import { Vue3Lottie } from 'vue3-lottie';

    import { useTutorialStore } from '@/stores/tutorial';

    import { storeToRefs } from 'pinia';
    import { ref, watchEffect, watch, computed } from 'vue';

    import { useIdle } from '@vueuse/core';

    const { idle: idleStep, reset: resetIdleStep } = useIdle(
        TUTORIAL_GESTURES_IDLE_TIME
    );
    const { idle: idleTutorial } = useIdle(TUTORIAL_IDLE_TIME);

    const tutorialStore = useTutorialStore();

    const { isActive, activeStepIndex } = storeToRefs(tutorialStore);

    const lottieKey = ref(0);
    const gestureAnimation = ref(null);
    const gestureCounter = ref(0);

    const isAnimationFinished = ref(false);

    const isLastStep = computed(() => {
        const lastStep = TUTORIAL_STEPS.length - 1;

        return activeStepIndex.value === lastStep;
    });

    const handleAnimationCompleted = () => {
        isAnimationFinished.value = true;
        gestureCounter.value += 1;

        if (
            isLastStep.value &&
            gestureCounter.value === TUTORIAL_GESTURES_LOOP
        ) {
            gestureCounter.value = 0;

            return;
        }

        resetIdleStep();
    };

    watch(idleStep, value => {
        if (value) {
            isAnimationFinished.value = false;

            if (
                gestureCounter.value === TUTORIAL_GESTURES_LOOP &&
                !isLastStep.value
            ) {
                activeStepIndex.value += 1;
                gestureCounter.value = 0;
            }
        }
    });

    watch(idleTutorial, value => {
        if (value) {
            isActive.value = false;
        }
    });

    watchEffect(async () => {
        gestureCounter.value = 0;
        resetIdleStep();

        const activeStep = TUTORIAL_STEPS[activeStepIndex.value];
        const animationName = activeStep.animation;

        const module = await import(
            `./assets/animations/${animationName}.json`
        );

        gestureAnimation.value = module.default;
        isAnimationFinished.value = false;

        lottieKey.value += 1;
    });
</script>

<style lang="scss" scoped>
    .rtr-gesture-animation {
        top: calc(50% - root-var(rtr-tutorial-height) / 2);
        left: 50%;
        transform: translate(-50%, -50%);
        transition:
            top addSuffix(rtr-transition-duration, 1ms),
            opacity addSuffix(rtr-transition-duration-short, 1ms) ease;

        width: calc(var(--rtr-applicative-zoom) / 100 * 40vh);
        width: calc(var(--rtr-applicative-zoom) / 100 * 40dvh);

        & :deep(.rtr-lottie-color1 *) {
            fill: root-var(base-primary);
            stroke: root-var(base-white);
        }

        & :deep(.rtr-lottie-color2 *) {
            fill: root-var(base-primary);
            stroke: root-var(base-primary);
        }
    }

    .rtr-fade-enter-from,
    .rtr-fade-leave-to {
        opacity: 0;
    }
</style>
