import { defineStore, acceptHMRUpdate } from 'pinia';
import { ref } from 'vue';

export const useFocusStore = defineStore('focus', () => {
    const isFocus = ref(false);

    return { isFocus };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useFocusStore, import.meta.hot));
}
