import { getCameraControls } from '@luxottica/rtr-viewer';

export const rotate = async ({ angle, time, withZoom = false }) => {
    const cameraControls = getCameraControls();

    const radians = angle * (Math.PI / 180);
    const frequency = 10;

    if (withZoom) {
        const distance = cameraControls.distance;

        cameraControls.dolly(-0.5, true);

        const zoomOutDistance = Math.min(
            distance + 0.5,
            cameraControls.maxDistance
        );
        const deltaDollyDistance = zoomOutDistance - distance;

        let delta = 0;

        const intervalDolly = setInterval(() => {
            delta += 1000 / frequency;

            const mappedDelta = delta / time;
            const value = zoomOutDistance - deltaDollyDistance * mappedDelta;

            cameraControls.dollyTo(value, true);
        }, 1000 / frequency);

        setTimeout(() => {
            clearInterval(intervalDolly);
        }, time);
    }

    // Old zoom animation
    // if (withZoom) {
    //     const distance = cameraControls.distance;
    //     const minDolly = distance * 0.7;
    //     const deltaDollyDistance = distance - minDolly;
    //     let delta = 0;

    //     const intervalDolly = setInterval(() => {
    //         delta += 1000 / frequency;

    //         const mappedDelta = delta / time;
    //         const dollyRadiants = (1 + Math.cos(2 * Math.PI * mappedDelta)) / 2;

    //         const value = minDolly + deltaDollyDistance * dollyRadiants;

    //         cameraControls.dollyTo(value, true);
    //     }, 1000 / frequency);

    //     setTimeout(() => {
    //         clearInterval(intervalDolly);
    //     }, time);
    // }

    const intervalRotate = setInterval(() => {
        cameraControls.rotate(radians / ((time / 1000) * frequency), 0, true);
    }, 1000 / frequency);

    setTimeout(() => {
        clearInterval(intervalRotate);
    }, time);
};
