import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useTutorialStore = defineStore('tutorial', () => {
    const isActive = ref(false);

    const activeStepIndex = ref(0);

    return { isActive, activeStepIndex };
});
