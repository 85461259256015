<template>
    <div class="rtr-overlay-layout rtr-tw-h-full rtr-tw-w-full">
        <LayoutMain id="rtr-layout-main" />
        <LayoutFooter
            id="rtr-layout-footer"
            class="rtr-tw-pointer-events-auto rtr-tw-bottom-0"
            :class="[showFooter ? 'rtr-show-footer' : 'rtr-hide-footer']"
        />
    </div>
</template>

<script setup lang="ts">
    import LayoutMain from '@/components/layout//LayoutMain.vue';
    import LayoutFooter from './LayoutFooter.vue';

    import { useUiStore } from '@/stores/ui';

    import { onMounted } from 'vue';
    import { storeToRefs } from 'pinia';

    const uiStore = useUiStore();

    const { isUiLoaded, showFooter } = storeToRefs(uiStore);

    onMounted(async () => {
        isUiLoaded.value = true;
    });
</script>

<style lang="scss" scoped>
    .rtr-overlay-layout {
        transition: grid-template-rows addSuffix(rtr-transition-duration, 1ms)
            ease;

        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-template-rows: minmax(0, 1fr) root-var(rtr-footer-height);
        grid-template-areas:
            'main'
            'footer';
    }

    #rtr-layout-main {
        z-index: $layer-main;
        grid-area: main;
    }

    #rtr-layout-footer {
        transition: transform addSuffix(rtr-transition-duration, 1ms) ease;

        z-index: $layer-footer;
        grid-area: footer;
    }

    .rtr-show-footer {
        transform: translateY(0);
    }

    .rtr-hide-footer {
        transform: translateY(100%);
    }
</style>
