import type { ITutorialStep } from '@/interfaces';

import {
    TR_TUTORIAL_DESCRIPTION_LONG_TAP,
    TR_TUTORIAL_DESCRIPTION_PANNING,
    TR_TUTORIAL_DESCRIPTION_PINCH,
    TR_TUTORIAL_DESCRIPTION_ROTATE,
    TR_TUTORIAL_DESCRIPTION_TOUCH_LONG_TAP,
    TR_TUTORIAL_DESCRIPTION_TOUCH_PANNING,
    TR_TUTORIAL_DESCRIPTION_TOUCH_PINCH,
    TR_TUTORIAL_DESCRIPTION_TOUCH_ROTATE,
    TR_TUTORIAL_STEP_TITLE_LONG_TAP,
    TR_TUTORIAL_STEP_TITLE_PANNING,
    TR_TUTORIAL_STEP_TITLE_PINCH,
    TR_TUTORIAL_STEP_TITLE_ROTATE,
} from '@/constants';

const longTapStep: ITutorialStep = {
    step: TR_TUTORIAL_STEP_TITLE_LONG_TAP,
    description: TR_TUTORIAL_DESCRIPTION_LONG_TAP,
    touchDescription: TR_TUTORIAL_DESCRIPTION_TOUCH_LONG_TAP,
    animation: 'longTap',
};

const panningStep: ITutorialStep = {
    step: TR_TUTORIAL_STEP_TITLE_PANNING,
    description: TR_TUTORIAL_DESCRIPTION_PANNING,
    touchDescription: TR_TUTORIAL_DESCRIPTION_TOUCH_PANNING,
    animation: 'panning',
    gesture: 'pan',
};

const pinchStep: ITutorialStep = {
    step: TR_TUTORIAL_STEP_TITLE_PINCH,
    description: TR_TUTORIAL_DESCRIPTION_PINCH,
    touchDescription: TR_TUTORIAL_DESCRIPTION_TOUCH_PINCH,
    animation: 'pinch',
    gesture: 'dolly',
};

const rotateStep: ITutorialStep = {
    step: TR_TUTORIAL_STEP_TITLE_ROTATE,
    description: TR_TUTORIAL_DESCRIPTION_ROTATE,
    touchDescription: TR_TUTORIAL_DESCRIPTION_TOUCH_ROTATE,
    animation: 'rotate',
    gesture: 'rotate',
};

export const TUTORIAL_STEPS = [pinchStep, panningStep, rotateStep, longTapStep];

export const TUTORIAL_GESTURES_LOOP = 2;
export const TUTORIAL_GESTURES_IDLE_TIME = 10 * 1000; // 10 seconds
export const TUTORIAL_IDLE_TIME = 3 * 60 * 1000; // 3 minutes

export const TUTORIAL_CONTINUOUS_INTERACTION_TIME = 500;
