import {
    ERROR_CODE_ASSET_LOADING,
    ERROR_CODE_ID_NOT_VALID,
    ERROR_CODE_UPC_NOT_AVAILABLE,
    ERROR_CODE_VALIDATION,
} from './errors';

// Accessibility
export const TR_ACCESSIBILITY_BACK = 'accessibility.back';
export const TR_ACCESSIBILITY_TOOLTIP = 'accessibility.tooltip';
export const TR_ACCESSIBILITY_NEXT = 'accessibility.next';
export const TR_ACCESSIBILITY_BUTTON_MOVE_DOWN =
    'accessibility.moveDown_button';
export const TR_ACCESSIBILITY_BUTTON_MOVE_LEFT =
    'accessibility.moveLeft_button';
export const TR_ACCESSIBILITY_BUTTON_MOVE_RIGHT =
    'accessibility.moveRight_button';
export const TR_ACCESSIBILITY_BUTTON_MOVE_UP = 'accessibility.moveUp_button';
export const TR_ACCESSIBILITY_BUTTON_ROTATE_DOWN =
    'accessibility.rotateDown_button';
export const TR_ACCESSIBILITY_BUTTON_ROTATE_LEFT =
    'accessibility.rotateLeft_button';
export const TR_ACCESSIBILITY_BUTTON_ROTATE_RIGHT =
    'accessibility.rotateRight_button';
export const TR_ACCESSIBILITY_BUTTON_ROTATE_UP =
    'accessibility.rotateUp_button';
export const TR_ACCESSIBILITY_BUTTON_ZOOM_IN = 'accessibility.zoomIn_button';
export const TR_ACCESSIBILITY_BUTTON_ZOOM_OUT = 'accessibility.zoomOut_button';

// Errors
export const TR_ERROR_CODE_TITLE = {
    [ERROR_CODE_ASSET_LOADING]: `error.${[ERROR_CODE_ASSET_LOADING]}`,
    [ERROR_CODE_ID_NOT_VALID]: `error.${[ERROR_CODE_ID_NOT_VALID]}`,
    [ERROR_CODE_UPC_NOT_AVAILABLE]: `error.${[ERROR_CODE_UPC_NOT_AVAILABLE]}`,
    [ERROR_CODE_VALIDATION]: `error.${[ERROR_CODE_VALIDATION]}`,
};

export const TR_ERROR_CODE_BUTTON = {
    [ERROR_CODE_ASSET_LOADING]: `error.${[ERROR_CODE_ASSET_LOADING]}_BUTTON`,
    [ERROR_CODE_ID_NOT_VALID]: `error.${[ERROR_CODE_ID_NOT_VALID]}_BUTTON`,
    [ERROR_CODE_UPC_NOT_AVAILABLE]: `error.${[
        ERROR_CODE_UPC_NOT_AVAILABLE,
    ]}_BUTTON`,
    [ERROR_CODE_VALIDATION]: `error.${[ERROR_CODE_VALIDATION]}_BUTTON`,
};

// Explosion
export const TR_EXPLOSION_TOOLTIP = 'explosion.tooltip';

// Hamburger
export const TR_HAMBURGER_TOOLTIP = 'hamburger.tooltip';
export const TR_HAMBURGER_CLOSE = 'hamburger.close';

// Overlay
export const TR_OVERLAY_TOOLTIP = 'overlay.tooltip';
export const TR_OVERLAY_CLOSE = 'overlay.close';

// Tutorial
export const TR_TUTORIAL_BACK = 'tutorial.back';
export const TR_TUTORIAL_CLOSE = 'tutorial.close';
export const TR_TUTORIAL_NEXT = 'tutorial.next';
export const TR_TUTORIAL_CONFIRM = 'tutorial.confirm';
export const TR_TUTORIAL_TOOLTIP = 'tutorial.tooltip';
export const TR_TUTORIAL_DESCRIPTION_LONG_TAP =
    'tutorial.desktop_longTap_description';
export const TR_TUTORIAL_DESCRIPTION_PANNING =
    'tutorial.desktop_panning_description';
export const TR_TUTORIAL_DESCRIPTION_PINCH =
    'tutorial.desktop_pinch_description';
export const TR_TUTORIAL_DESCRIPTION_ROTATE =
    'tutorial.desktop_rotate_description';
export const TR_TUTORIAL_DESCRIPTION_TOUCH_LONG_TAP =
    'tutorial.mobile_longTap_description';
export const TR_TUTORIAL_DESCRIPTION_TOUCH_PANNING =
    'tutorial.mobile_panning_description';
export const TR_TUTORIAL_DESCRIPTION_TOUCH_PINCH =
    'tutorial.mobile_pinch_description';
export const TR_TUTORIAL_DESCRIPTION_TOUCH_ROTATE =
    'tutorial.mobile_rotate_description';
export const TR_TUTORIAL_STEP_TITLE_LONG_TAP = 'tutorial.longTap_step_title';
export const TR_TUTORIAL_STEP_TITLE_PANNING = 'tutorial.panning_step_title';
export const TR_TUTORIAL_STEP_TITLE_PINCH = 'tutorial.pinch_step_title';
export const TR_TUTORIAL_STEP_TITLE_ROTATE = 'tutorial.rotate_step_title';
