<template>
    <div class="rtr-tw-flex rtr-tw-flex-col rtr-tw-items-center rtr-tw-gap-4">
        <button
            class="rtr-accessibility-button rtr-tw-border"
            :aria-label="$t(data.translation)"
            @click="handleClick"
        >
            <component
                :is="icon"
                class="rtr-accessibility-button-icon rtr-tw-mx-auto rtr-tw-my-0"
                :class="[data.icon.class ? data.icon.class : '']"
            />
        </button>

        <span class="rtr-accessibility-button-text rtr-tw-text-center">
            {{ $t(data.translation) }}
        </span>
    </div>
</template>

<script setup lang="ts">
    import type { IAccessibilityButton } from '@/interfaces';

    import { defineAsyncComponent } from 'vue';

    import { getCameraControls } from '@luxottica/rtr-viewer';

    interface IProps {
        data: IAccessibilityButton;
    }

    const props = defineProps<IProps>();

    const icon = defineAsyncComponent(
        () => import(`./assets/${props.data.icon.name}.svg?component`)
    );

    const handleClick = () => {
        const cameraControls = getCameraControls();

        const actionSettings = props.data.action;

        cameraControls[actionSettings.function](
            // @ts-ignore
            ...actionSettings.settings,
            true
        );
    };
</script>

<style lang="scss" scoped>
    .rtr-accessibility-button {
        --size: 64px;

        height: calc(var(--rtr-applicative-zoom) / 100 * var(--size));
        width: calc(var(--rtr-applicative-zoom) / 100 * var(--size));

        border-color: root-var(base-black);
        background: root-var(base-white);

        border-radius: 50%;
    }

    .rtr-accessibility-button-icon {
        scale: calc(var(--rtr-applicative-zoom) / 100);
    }

    .rtr-accessibility-button-text {
        color: root-var(base-text-dark);
    }
</style>
