<template>
    <div class="rtr-tw-relative">
        <Transition name="rtr-fade">
            <div
                v-show="displayOverview"
                id="rtr-overview-shadow"
                class="rtr-tw-absolute rtr-tw-h-full rtr-tw-w-full"
            ></div>
        </Transition>

        <ButtonsContainer
            id="rtr-animation-block"
            class="rtr-tw-absolute rtr-tw-top-0 rtr-tw-right-0 rtr-tw-pt-6 rtr-tw-pr-6"
        >
            <ButtonOverlay
                v-if="experience !== 'overlay'"
                class="rtr-tw-text-center"
                :disabled="displayOverview"
            />
        </ButtonsContainer>

        <template v-if="zoomBoundary !== '200' || experience === 'embedded'">
            <ButtonsContainer
                id="rtr-control-block"
                class="rtr-tw-absolute rtr-tw-bottom-0 rtr-tw-left-0 rtr-tw-pb-6 rtr-tw-pl-6"
            >
                <ButtonExplosion
                    :disabled="isTutorialActive || displayOverview"
                    :pending="!isExplosionFinished"
                />
                <ButtonAccessibility
                    :disabled="isTutorialActive || displayOverview"
                />
                <ButtonTutorial
                    :disabled="isTutorialActive || displayOverview"
                />
            </ButtonsContainer>

            <ButtonsContainer
                id="rtr-animation-block"
                class="rtr-tw-absolute rtr-tw-bottom-0 rtr-tw-right-0 rtr-tw-pb-6 rtr-tw-pr-6"
                :class="{
                    'rtr-animation-block--overview': displayOverview,
                }"
            >
                <ButtonRotate :disabled="isTutorialActive || displayOverview" />
            </ButtonsContainer>
        </template>

        <template v-else>
            <MenuContainer
                class="rtr-tw-absolute rtr-tw-bottom-0 rtr-tw-right-0 rtr-tw-pb-6 rtr-tw-pr-6"
            />

            <TransitionGroup name="rtr-fade">
                <ButtonsContainer
                    v-if="isMenuActive && isLandscape"
                    id="rtr-landscape-menu"
                    class="rtr-tw-absolute rtr-tw-left-1/2 rtr-tw-top-1/2 !rtr-tw-flex-row"
                    ref="landscapeMenu"
                >
                    <ButtonExplosion
                        class="rtr-tw-text-center"
                        :style="{ width: `${maxMenuButtonSize}px` }"
                        @click="isMenuActive = false"
                    />
                    <ButtonAccessibility
                        :style="{ width: `${maxMenuButtonSize}px` }"
                        @click="isMenuActive = false"
                    />
                    <ButtonTutorial
                        class="rtr-tw-text-center"
                        :style="{ width: `${maxMenuButtonSize}px` }"
                        @click="isMenuActive = false"
                    />
                    <ButtonRotate
                        class="rtr-tw-text-center"
                        :style="{ width: `${maxMenuButtonSize}px` }"
                        @click="isMenuActive = false"
                    />
                </ButtonsContainer>
            </TransitionGroup>
        </template>

        <Transition name="rtr-fade">
            <TutorialContainer
                v-if="isTutorialActive && experience === 'overlay'"
                class="rtr-tw-z-10 rtr-tw-h-full"
            />
        </Transition>
    </div>
</template>

<script setup lang="ts">
    import ButtonOverlay from '@/components/buttons/ButtonOverlay.vue';
    import ButtonsContainer from '@/components/buttons/ButtonsContainer.vue';
    import ButtonAccessibility from '@/components/buttons/ButtonAccessibility.vue';
    import ButtonExplosion from '@/components/buttons/ButtonExplosion.vue';
    import ButtonRotate from '@/components/buttons/ButtonRotate.vue';
    import ButtonTutorial from '@/components/buttons/ButtonTutorial.vue';
    import MenuContainer from '@/components/menu/MenuContainer.vue';

    import TutorialContainer from '@/modules/overlay/components/tutorial/TutorialContainer.vue';

    import { onBeforeUnmount, ref } from 'vue';
    import { storeToRefs } from 'pinia';

    import { useExplosionStore } from '@/stores/explosion';
    import { useTutorialStore } from '@/stores/tutorial';
    import { useUiStore } from '@/stores/ui';
    import { useZoomStore } from '@/stores/zoom';

    import { useResizeObserver } from '@vueuse/core';

    const explosionStore = useExplosionStore();
    const tutorialStore = useTutorialStore();
    const uiStore = useUiStore();
    const zoomStore = useZoomStore();

    const { isFinished: isExplosionFinished } = storeToRefs(explosionStore);
    const { isActive: isTutorialActive } = storeToRefs(tutorialStore);
    const { displayOverview, isLandscape, isMenuActive, experience } =
        storeToRefs(uiStore);
    const { zoomBoundary } = storeToRefs(zoomStore);

    const landscapeMenu = ref(null);
    const maxMenuButtonSize = ref(null);

    const { stop: stopObserver } = useResizeObserver(landscapeMenu, entries => {
        const entry = entries[0];
        const children = entry.target.children;

        const arrayOfChildren = Array.from(children);

        const maxSize = arrayOfChildren.reduce((acc, child) => {
            const { width } = child.getBoundingClientRect();

            return Math.max(acc, width);
        }, 0);

        maxMenuButtonSize.value = maxSize;
    });

    onBeforeUnmount(() => {
        stopObserver();
    });
</script>

<style lang="scss" scoped>
    #rtr-overview-shadow {
        background-color: root-var(alpha-black-light);
    }

    #rtr-animation-block {
        transition: filter addSuffix(rtr-transition-duration-short, 1ms) ease;
    }

    #rtr-landscape-menu {
        transform: translateX(-50%);
    }

    .rtr-animation-block--overview {
        filter: blur(2px);
    }

    .rtr-fade-enter-active,
    .rtr-fade-leave-active {
        transition: opacity addSuffix(rtr-transition-duration-short, 1ms) ease;
    }

    .rtr-fade-enter-from,
    .rtr-fade-leave-to {
        opacity: 0;
    }
</style>
