<template>
    <Button
        :disabled="disabled || isRotationActive"
        class="rtr-tw-relative"
        :aria-label="$t(ariaLabel)"
        @click="handleMenu"
    >
        <div
            v-if="!isMenuActive && hasActiveFeatures"
            id="rtr-menu-notification"
            class="rtr-tw-absolute rtr-tw-right-2 rtr-tw-top-2 rtr-tw-h-3 rtr-tw-w-3"
        />

        <component :is="menuIcon" />
    </Button>
</template>

<script setup lang="ts">
    import Button from './Button.vue';

    import MenuSvg from './assets/menu-open.svg?component';
    import CloseSvg from './assets/menu-close.svg?component';

    import { TR_HAMBURGER_TOOLTIP, TR_HAMBURGER_CLOSE } from '@/constants';

    import { useAccessibilityStore } from '@/stores/accessibility';
    import { useAnimationsStore } from '@/stores/animations';
    import { useExplosionStore } from '@/stores/explosion';
    import { useUiStore } from '@/stores/ui';

    import { computed } from 'vue';
    import { storeToRefs } from 'pinia';

    interface IProps {
        disabled?: boolean;
    }

    defineProps<IProps>();

    const accessibilityStore = useAccessibilityStore();
    const animationsStore = useAnimationsStore();
    const explosionStore = useExplosionStore();
    const uiStore = useUiStore();

    const menuIcon = computed(() => (isMenuActive.value ? CloseSvg : MenuSvg));

    const ariaLabel = computed(() =>
        isMenuActive.value ? TR_HAMBURGER_TOOLTIP : TR_HAMBURGER_CLOSE
    );

    const hasActiveFeatures = computed(
        () => accessibilityStore.isActive || explosionStore.isActive
    );

    const { isRotationActive } = storeToRefs(animationsStore);
    const { isMenuActive } = storeToRefs(uiStore);

    const handleMenu = () => {
        isMenuActive.value = !isMenuActive.value;
    };
</script>

<style lang="scss" scoped>
    #rtr-menu-notification {
        --size: 10px;

        border-radius: 50%;

        height: calc(var(--rtr-applicative-zoom) / 100 * var(--size));
        width: calc(var(--rtr-applicative-zoom) / 100 * var(--size));

        max-height: 14px;
        max-width: 14px;

        background-color: root-var(base-text-dark);
    }
</style>
