import { defineStore } from 'pinia';
import { ref } from 'vue';
import { asyncTimeout } from '@/utils/promises';

import { toggleExplosion as toggleRtrExplosion } from '@luxottica/rtr-viewer';

export const useExplosionStore = defineStore('explosion', () => {
    const isActive = ref(false);

    const isFinished = ref(true);

    const toggleExplosion = async () => {
        toggleRtrExplosion();

        // 2023-04-27 16:38:43 - Marco Borean
        // Quick solution to wait until the explosion is over
        isFinished.value = false;

        await asyncTimeout(2000);

        isFinished.value = true;
    };

    return { isActive, isFinished, toggleExplosion };
});
