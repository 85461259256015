<template>
    <div id="rtr-tutorial-step" class="rtr-tw-w-full rtr-tw-p-[3%]">
        <p
            id="rtr-tutorial-step-title"
            class="Text-lgBold rtr-tw-mb-2 first-letter:rtr-tw-capitalize"
        >
            {{ $t(activeStep.step) }}
        </p>

        <div
            id="rtr-tutorial-step-content"
            class="rtr-tw-grid rtr-tw-items-center rtr-tw-justify-center rtr-tw-grid-rows-1 rtr-tw-grid-cols-[auto_minmax(0,1fr)_auto] rtr-tw-gap-[5%]"
        >
            <div class="rtr-tutorial-button-container">
                <TutorialButton
                    name="back"
                    v-show="activeStepIndex !== 0"
                    @click="handleBackButton"
                >
                    <BackSvg class="rtr-tw-relative rtr-tw-right-0.5" />
                </TutorialButton>
            </div>

            <p
                id="rtr-tutorial-step-description"
                class="Text-lgRegular rtr-tw-grow"
            >
                {{ $t(stepDescription) }}
            </p>

            <div class="rtr-tutorial-button-container">
                <TutorialButton
                    name="next"
                    v-show="activeStepIndex !== lastStepIndex"
                    @click="handleNextButton"
                >
                    <NextSvg class="rtr-tw-relative rtr-tw-left-0.5" />
                </TutorialButton>
            </div>
        </div>

        <button
            v-if="activeStepIndex === lastStepIndex"
            id="rtr-tutorial-confirm-button"
            class="CTAsMedium rtr-tw-mt-2 rtr-tw-py-2.5 rtr-tw-px-6 rtr-tw-uppercase"
            :aria-label="$t(TR_TUTORIAL_CONFIRM)"
            @click="closeTutorial"
        >
            {{ $t(TR_TUTORIAL_CONFIRM) }}
        </button>

        <TutorialDots class="rtr-tw-mt-4" />
    </div>
</template>
<script setup lang="ts">
    import TutorialButton from './TutorialButton.vue';
    import TutorialDots from './TutorialDots.vue';

    import BackSvg from './assets/icons/back.svg';
    import NextSvg from './assets/icons/next.svg';

    import {
        TR_TUTORIAL_CONFIRM,
        TUTORIAL_STEPS,
        TUTORIAL_CONTINUOUS_INTERACTION_TIME,
    } from '@/constants';
    import type { ITutorialStep } from '@/interfaces';

    import { useActionsStore } from '@/stores/actions';
    import { useFocusStore } from '@/stores/focus';
    import { useTutorialStore } from '@/stores/tutorial';

    import { computed, watch } from 'vue';
    import { storeToRefs } from 'pinia';

    import { watchDebounced } from '@vueuse/core';

    import { isTouchDevice } from '@luxottica/rtr-viewer';

    const actionsStore = useActionsStore();
    const focusStore = useFocusStore();
    const tutorialStore = useTutorialStore();

    const { actionsStatus } = storeToRefs(actionsStore);
    const { isFocus } = storeToRefs(focusStore);
    const { isActive: isTutorialActive, activeStepIndex } =
        storeToRefs(tutorialStore);

    const stepDescription = computed(() =>
        isTouchDevice()
            ? activeStep.value.touchDescription
            : activeStep.value.description
    );

    const lastStepIndex = TUTORIAL_STEPS.length - 1;

    const activeStep = computed<ITutorialStep>(
        () => TUTORIAL_STEPS[activeStepIndex.value]
    );

    const handleBackButton = () => {
        activeStepIndex.value--;
    };

    const handleNextButton = () => {
        if (activeStepIndex.value === lastStepIndex) {
            closeTutorial();

            return;
        }

        activeStepIndex.value++;
    };

    const closeTutorial = () => {
        isTutorialActive.value = false;
    };

    // Discrete actions
    watch([actionsStatus, isFocus], ([status, focus]) => {
        const gesture = activeStep.value?.gesture;

        if (!gesture) {
            if (focus) {
                handleNextButton();
            }

            return;
        }

        if (gesture === 'dolly' && status[gesture]) {
            handleNextButton();
        }
    });

    // Continuous actions
    watchDebounced(
        actionsStatus,
        status => {
            const gesture = activeStep.value?.gesture;

            if (status[gesture]) {
                handleNextButton();
            }
        },
        { debounce: TUTORIAL_CONTINUOUS_INTERACTION_TIME }
    );
</script>

<style lang="scss" scoped>
    #rtr-tutorial-confirm-button {
        background-color: root-var(base-primary);
        color: root-var(base-text-light);

        // &:hover {
        //     background-color: $clr-blue-2;
        // }
    }

    .rtr-tutorial-button-container {
        --size: 48px;

        height: calc(var(--rtr-applicative-zoom) / 100 * var(--size));
        width: calc(var(--rtr-applicative-zoom) / 100 * var(--size));
    }
</style>
